import * as React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import Sidebar, { SidebarProps } from '../sidebar/sidebar';

type Props = {
  section?: string;
  name: string;
  content: object;
  sidebar?: SidebarProps;
  videoEmbeds?: string[];
};

const Page: React.SFC<Props> = ({
  section,
  name,
  content,
  sidebar,
  videoEmbeds,
}) => {
  const heading = !!section || <h1>{name}</h1>;
  return (
    <article
      className={`${
        section ? `section-${section} ` : ''
      }text-neutral-darker p-3`}
    >
      <div className="md:flex">
        <div className="md:flex-1">
          {heading}
          <BlockContent blocks={content} />
          {videoEmbeds &&
            videoEmbeds.map(embed => (
              <div
                className="my-3 embed-responsive aspect-16x9"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{
                  __html: embed,
                }}
              />
            ))}
        </div>
        {sidebar && (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          <Sidebar {...sidebar} />
        )}
      </div>
    </article>
  );
};

export default Page;
