import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/global/layout/layout';
import Page from '../components/global/page/page';
import AirtableForm from '../components/global/airtable-form/airtable-form';

type Props = {
  data: {
    sanityPage: {
      name: string;
      slug: {
        current: string;
      };
      content: object;
      videoEmbeds: string[];
      sidebar: {
        images: {
          label: string;
          asset: {
            url: string;
          };
        }[];
        links: {
          label: string;
          destination: {
            slug: {
              current: string;
            };
          };
        }[];
        videoEmbeds: string[];
      };
    };
  };
};

export const pageQuery = graphql`
  query PageQuery($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      name
      slug {
        current
      }
      content: _rawContent
      videoEmbeds
      sidebar {
        images {
          label
          asset {
            url
          }
        }
        links {
          label
          destination {
            slug {
              current
            }
          }
        }
        videoEmbeds
      }
    }
  }
`;

const PageTemplate: React.SFC<Props> = ({ data }) => {
  let section;

  switch (data.sanityPage.slug.current) {
    case 'believe':
    case 'become':
    case 'belong':
      section = data.sanityPage.slug.current;
      break;
    default:
      break;
  }

  function getSidebar() {
    if (!(data.sanityPage && data.sanityPage.sidebar)) {
      return undefined;
    }
    const { sidebar } = data.sanityPage;
    return {
      ...sidebar,
      images: (sidebar.images || []).map(img => ({
        label: img.label,
        src: img.asset && img.asset.url,
      })),
      links: (sidebar.links || []).map(link => ({
        label: link.label,
        url:
          link.destination &&
          link.destination.slug &&
          link.destination.slug.current,
      })),
    };
  }

  function renderComponent(slug) {
    switch (slug) {
      case 'contact':
        return <AirtableForm title="Contact form" formId="shrwkDCea74HgsSMZ" />;
      default:
        return (
          <Page
            section={section}
            name={data.sanityPage.name}
            content={data.sanityPage.content}
            sidebar={getSidebar()}
            videoEmbeds={data.sanityPage.videoEmbeds}
          />
        );
    }
  }

  return (
    <Layout section={section}>
      {renderComponent(data.sanityPage.slug.current)}
    </Layout>
  );
};

export default PageTemplate;
